import Constants from "../constants";

const handleCount = () => {
  const now = Date.now();
  const accessedAt = localStorage.getItem(
    Constants.KEY_STORED_LAST_ACCESSED_AT
  );
  const accessCount = localStorage.getItem(Constants.KEY_STORED_ACCESS_COUNT);
  localStorage.setItem(
    Constants.KEY_STORED_LAST_ACCESSED_AT,
    JSON.stringify(now)
  );

  if (!accessCount) {
    localStorage.setItem(Constants.KEY_STORED_ACCESS_COUNT, JSON.stringify(1));
    return;
  }

  if (accessedAt && (now - JSON.parse(accessedAt)) / (3600 * 1000) > 24) {
    const count: number = JSON.parse(accessCount) + 1;
    localStorage.setItem(
      Constants.KEY_STORED_ACCESS_COUNT,
      JSON.stringify(count)
    );
  }
};

document.addEventListener("DOMContentLoaded", handleCount);
